.contactPage{
    height: 85vh;
    color:#fff;
    background-color: #000;
    padding-top: 20px;
    text-align: center;
    font-family: 'Girassol';
    box-shadow: 0px 0px 25px 1px #000;
}

.contactPage h1{
    font-size: 50px;
    /*text-shadow: 0px 0px 0px #fff;
*/
}

.address{
    margin-top: 30px;
    margin-left: 30px;
    padding-left: 30px;
    text-align: left;
    position: relative;
    float: left;
    width: 30%;
    
}

.address h1{
    color: #fff;
    /*background: -webkit-linear-gradient(135deg,#9733EE,#9733EE);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    */
    font-size: 30px;
    text-shadow: 0px 0px 0px #fff;
}

.address p{
    font-size: 20px;
    font-weight: lighter;
}

.address a{
    color: #fff;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
}


@media (max-width: 991.98px) {

.contactPage{
    height: 200vh;
    background-color: #000;
    padding-top: 100px;
    text-align: center;
}
.address{
    margin-left: 5px;
    padding-left: 30px;
    text-align: left;
    position: relative;
    float: left;
    width: 90%
}


}