@import url(https://fonts.googleapis.com/css2?family=Girassol&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mallanna&family=Sree+Krushnadevaraya&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Girassol&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chathura:wght@100&family=Dhurjati&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patua+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Girassol&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chathura:wght@100&family=Dhurjati&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patua+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chathura:wght@100&family=Dhurjati&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Girassol&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mandali&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&family=Old+Standard+TT:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);
@-webkit-keyframes example {
    0%   {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    10%  {background-image: linear-gradient(130deg,#7F00FF,#E100FF);}
    20%  {background-image: linear-gradient(170deg,#7F00FF,#E100FF);}
    30%  {background-image: linear-gradient(210deg,#7F00FF,#E100FF);}
    40%  {background-image: linear-gradient(250deg,#7F00FF,#E100FF);}
    50%  {background-image: linear-gradient(290deg,#7F00FF,#E100FF);}
    60%  {background-image: linear-gradient(330deg,#7F00FF,#E100FF);}
    70% {background-image: linear-gradient(370deg,#7F00FF,#E100FF);}
    80% {background-image: linear-gradient(410deg,#7F00FF,#E100FF);}
    90% {background-image: linear-gradient(450deg,#7F00FF,#E100FF);}
}
@keyframes example {
    0%   {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    10%  {background-image: linear-gradient(130deg,#7F00FF,#E100FF);}
    20%  {background-image: linear-gradient(170deg,#7F00FF,#E100FF);}
    30%  {background-image: linear-gradient(210deg,#7F00FF,#E100FF);}
    40%  {background-image: linear-gradient(250deg,#7F00FF,#E100FF);}
    50%  {background-image: linear-gradient(290deg,#7F00FF,#E100FF);}
    60%  {background-image: linear-gradient(330deg,#7F00FF,#E100FF);}
    70% {background-image: linear-gradient(370deg,#7F00FF,#E100FF);}
    80% {background-image: linear-gradient(410deg,#7F00FF,#E100FF);}
    90% {background-image: linear-gradient(450deg,#7F00FF,#E100FF);}
}
@-webkit-keyframes example1 {
    0%   {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    10%  {background-image: linear-gradient(90deg,#E100FF,#7F00FF);}
    20%  {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    30%  {background-image: linear-gradient(90deg,#E100FF,#7F00FF);}
    40%  {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    50%  {background-image: linear-gradient(90deg,#E100FF,#7F00FF);}
    60%  {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    70%  {background-image: linear-gradient(90deg,#E100FF,#7F00FF);}
    80%  {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    90%  {background-image: linear-gradient(90deg,#E100FF,#7F00FF);}
}
@keyframes example1 {
    0%   {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    10%  {background-image: linear-gradient(90deg,#E100FF,#7F00FF);}
    20%  {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    30%  {background-image: linear-gradient(90deg,#E100FF,#7F00FF);}
    40%  {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    50%  {background-image: linear-gradient(90deg,#E100FF,#7F00FF);}
    60%  {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    70%  {background-image: linear-gradient(90deg,#E100FF,#7F00FF);}
    80%  {background-image: linear-gradient(90deg,#7F00FF,#E100FF);}
    90%  {background-image: linear-gradient(90deg,#E100FF,#7F00FF);}
}
.liveBody{
    transition: all .2s ease-in-out;
    background-image: linear-gradient(90deg,#7F00FF,#E100FF);
    width:100%;
    height:auto;
    padding-top:60px ;    


}

.liveWrap{
    
}

.welcomeDiv{
    margin-top:150px;
    text-align: center;
    margin-bottom: 150px;
    color:#fff;
    font-family: Georgia, serif
}

.welcomeDiv h1{
    font-size: 50px;
}

.fhclive{
    font-size: 75px;
}

.video-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
  
    margin-top: 60px;
    
}
.video-responsive iframe{
    left:0;
    right:0;
    top:0;
    height:70%;
    width:70%;
    margin: auto;
    position:absolute;
    box-shadow: 2px 3px 13px 1px #000;
    border-radius: 7px;
}

.videoTitle{
    margin-top:10px;
    text-align: center;
    color:#000;
    font-size: 40px;
    font-family: 'Girassol', cursive;
}

.previousMessages{
    margin-top: 20px;
}

.bible{
    height:auto;
}

@media only screen and (max-width: 992px) {
    .video-responsive iframe{
        height:95%;
        width:95%;
    }
    .videoTitle{
        margin-top:50px;
        margin-bottom: -10px;
        font-size: 25px;
        
    }

}
.bibleBag{
     /* Black background with opacity */
     width:100%
}

.biblepage{
    width:100vw;
    height:100auto;
    padding-top: 25px ;
    padding-bottom: 100px;
}
.bibleBg img{
    object-fit: cover;
    width:100%;
    height:100%
}
.bibleBg{
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
width: 100%;
height: 100%;
object-fit: cover;
z-index:-10
}
.bgOverlay{
    position: fixed; /* Sit on top of the page content */
     /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(135deg,#780206,#061161); /* Black background with opacity */
    z-index: -9; /* Specify a stack order in case you're using a different order for other elements */
     /* Add a pointer on hover */
    opacity: 0.2;
}

.biblebody h1{
}
#biblebook{
 width:80%;
 margin: auto;
 margin-top: 50px;
 
}
#flipbook{
    width:75%;
    height: 10px;
    
}

.hardo{
    width:100%
}

.hardo > img{
    object-fit: cover;
    width:100%;
    height:100%
}

.hardi{
    background-color: #922222;
    width: 100%;
    height: 100%;
}

#flipbook .page{
    background-color: #fff;
    /*overflow-y: scroll;
 */
    border: 2px solid #808080;
}

.biblebody h1{
    color: #fff;
    font-family: "Girassol";
    margin-bottom: 40px;
    font-size: 50px;
}

.biblebody{
    width: 80%;
    margin: auto;
    text-align: center;
    color: #fff;
    margin-top: 130px;
}


#Book{
    width: 50%;
    background-color: #fff;
    font-family: 'Sree Krushnadevaraya', serif;
    font-size: 25px;
    padding: 3px;
    border-radius: 7px;
    box-shadow: 2px 3px 7px 1px #808080;
    margin: 7px;
    padding-left: 13px;
}

option{
    font-family: 'Mallanna', sans-serif;
     font-size: 23px;
}
#Chapter{
    width: 50%;
    background-color: #fff;
    font-family: 'Sree Krushnadevaraya', serif;
    font-size: 23px;
    padding: 3px;
    border-radius: 7px;
    box-shadow: 2px 3px 7px 1px #808080;
    margin: 7px;
    padding-left: 13px;
}

#Verse{
    width: 50%;
    background-color: #fff;
    font-family: 'Sree Krushnadevaraya', serif;
    font-size: 23px;
    padding: 3px;
    border-radius: 7px;
    box-shadow: 2px 3px 7px 1px #808080;
    margin: 7px;
    padding-left: 13px;
}

.display{
    padding: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: 'Sree Krushnadevaraya', serif;
    font-weight:500;
    font-size: 25px;
    background-color: #FFF;
    border-radius: 10px;
    color: black;
    box-shadow: 0px 0px 13px 1px #fff;
    padding-bottom: 50px;
    text-align: left;
    max-height:350px;
    overflow-y: auto;
    overflow-x: hidden;
    
}

.display h1{
    text-align: center;
}

.copy{
    width: 60px;
    height: 25px;
    text-align: center;
    position: relative;
    background-color: black;
    color: #fff;
    float: right;
    border-radius: 5px;
    margin: 2px;
    margin-top:-15px    
}

.copy h1{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
    color: white;
}

@media only screen and (max-width: 992px) {

    #Book{
        width: 80%;
    }
    
    option{
    }
    #Chapter{
        width: 80%;
    }
    
    #Verse{
        width: 80%;
    }
    .biblebody{
        width: 100%;
    }

    .display{
        width: 95%;
        margin: auto;
        margin-top: 25px;
        margin-bottom: 25px;
        text-align: left;
        padding: 20px;
        padding-bottom: 40px;
        max-height:500px;
        
    }
    .verser{
        
    }

}
@-webkit-keyframes example {
    0%   {background-image:  linear-gradient(90deg,#41295a,#2F0743);}
    5%  {background-image:  linear-gradient(110deg,#41295a,#2F0743);}
    10%  {background-image: linear-gradient(130deg,#41295a,#2F0743);}
    15%  {background-image: linear-gradient(150deg,#41295a,#2F0743);}
    20%  {background-image: linear-gradient(170deg,#41295a,#2F0743);}
    25%  {background-image: linear-gradient(190deg,#41295a,#2F0743);}
    30%  {background-image: linear-gradient(210deg,#41295a,#2F0743);}
    35%  {background-image: linear-gradient(230deg,#41295a,#2F0743);}
    40%  {background-image: linear-gradient(250deg,#41295a,#2F0743);}
    45%  {background-image: linear-gradient(270deg,#41295a,#2F0743);}
    50%  {background-image: linear-gradient(290deg,#41295a,#2F0743);}
    55%  {background-image: linear-gradient(310deg,#41295a,#2F0743);}
    60%  {background-image: linear-gradient(330deg,#41295a,#2F0743);}
    65%  {background-image: linear-gradient(350deg,#41295a,#2F0743);}
    70% {background-image:  linear-gradient(370deg,#41295a,#2F0743);}
    75%  {background-image: linear-gradient(390deg,#41295a,#2F0743);}
    80% {background-image:  linear-gradient(410deg,#41295a,#2F0743);}
    85%  {background-image: linear-gradient(420deg,#41295a,#2F0743);}
    90% {background-image:  linear-gradient(430deg,#41295a,#2F0743);}
    95%  {background-image: linear-gradient(440deg,#41295a,#2F0743);}
    100%  {background-image:linear-gradient(450deg,#41295a,#2F0743);}
    
}

@keyframes example {
    0%   {background-image:  linear-gradient(90deg,#41295a,#2F0743);}
    5%  {background-image:  linear-gradient(110deg,#41295a,#2F0743);}
    10%  {background-image: linear-gradient(130deg,#41295a,#2F0743);}
    15%  {background-image: linear-gradient(150deg,#41295a,#2F0743);}
    20%  {background-image: linear-gradient(170deg,#41295a,#2F0743);}
    25%  {background-image: linear-gradient(190deg,#41295a,#2F0743);}
    30%  {background-image: linear-gradient(210deg,#41295a,#2F0743);}
    35%  {background-image: linear-gradient(230deg,#41295a,#2F0743);}
    40%  {background-image: linear-gradient(250deg,#41295a,#2F0743);}
    45%  {background-image: linear-gradient(270deg,#41295a,#2F0743);}
    50%  {background-image: linear-gradient(290deg,#41295a,#2F0743);}
    55%  {background-image: linear-gradient(310deg,#41295a,#2F0743);}
    60%  {background-image: linear-gradient(330deg,#41295a,#2F0743);}
    65%  {background-image: linear-gradient(350deg,#41295a,#2F0743);}
    70% {background-image:  linear-gradient(370deg,#41295a,#2F0743);}
    75%  {background-image: linear-gradient(390deg,#41295a,#2F0743);}
    80% {background-image:  linear-gradient(410deg,#41295a,#2F0743);}
    85%  {background-image: linear-gradient(420deg,#41295a,#2F0743);}
    90% {background-image:  linear-gradient(430deg,#41295a,#2F0743);}
    95%  {background-image: linear-gradient(440deg,#41295a,#2F0743);}
    100%  {background-image:linear-gradient(450deg,#41295a,#2F0743);}
    
}

.Chordsbody{
    background-image: linear-gradient(90deg,#EB3349,#F45C43);
    height: auto;
    width:100%;
    padding-top: 70px;
    padding-bottom: 70px;
}

.welcomeDiv{
    margin-top:150px;
    text-align: center;
    margin-bottom: 150px;
    color:#fff;
    font-family: Georgia, serif
}

.welcomeDiv h1{
    font-size: 50px;
}

.fhclive{
    font-size: 75px;
}



.Ctitles{
    width: 60%;
    margin: auto;
    height:auto;
    padding-top: 50px;
    box-shadow: 1px 1px 13px 1px #fff;
    border-radius: 1px;
    /*background-image: url("./images/lyricBag.png");
    background-repeat: repeat-y;
    background-position: right;
    background-clip: border-box;
    */
    background-color: white;
    border-radius: 13px;
    padding-bottom: 50px;
}

.Ctitle{
    width:90%;
    margin:auto;
    background-image: linear-gradient(90deg,#EB3349,#F45C43);
    text-align: center;
    padding:7px;
    border-radius: 7px;
    font-size: 30px;
    font-family: 'Girassol', cursive;
    -webkit-animation-name: exampl;
            animation-name: exampl;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -moz-animation-timing-function: initial;
    margin-bottom: 10px;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
   /* box-shadow: 1px 1px 10px 1px rgb(57, 78, 117);*/
}

.titleWrap{
    padding: 0px 0px;
    padding-top: 100px;
}

.teluguTitle{
    font-family: "Dhurjati"; 
    font-size: "33px" 
}
.titleLink{
    color: #fff;
    
}

.titleAlpha{
    width:90%;
    margin:auto;
    margin-bottom: 15px;
    font-size: 30px;
    font-family: 'Girassol', cursive;
}

.lyricNav{
    width: 80%;
    margin: auto;
}

.titleAlphaM{
    position: relative;
    float:left;
    margin:5px;
    font-size: 25px;
    font-family: 'Girassol', cursive;
}


.chordpage{
    height: auto;
    width:100%;
    padding-top: 70px;
    background-image: linear-gradient(90deg,#EB3349,#F45C43);
    padding-bottom: 50px;

}
.chordTitle{
    font-family: "Dhurjati";
    font-size: 55px; 
    margin-bottom:40px;
}



.langButton{
    background-color: #fff;
    border: 2px solid #000;
    border-bottom: 0px;
    width: 275px;
    margin-left:45%;
    margin-top: 30px;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    border-radius: 7px;
    
}

.chordDiv{
    background-color: white;
    width:65%;
    margin-left:25px;
    padding:30px;
    padding-left: 60px;
    margin-top: 0px;
    box-shadow: 1px 1px 13px 1px #fff;
    font-size: 20px;
    
}



.tools{
    height: auto;
    padding:25px;
    background-color: #fff;
    width: 25%;
    margin:auto;
    margin-top: 173px;
    margin-right: 20px;
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    box-shadow: 1px 1px 13px 1px #808080;
    text-align: center;
    font-family: 'Bebas Neue', cursive;
}

.back{
    font-family: 'Patua One', cursive;
    margin-left:25px;
    font-size: 25;
    color: #fff;
    width: 65px;
    padding:2px;
    padding-left: 0px;
    border-radius: 5px;
    background-color: rgb(26, 1, 1);
    border: none;
    box-shadow: 1px 1px 5px 1px #000;
    margin-top: 13px;
    
}
.chordBox{
    font-family: 'Sree Krushnadevaraya', serif;
    font-size: 25px;
    line-height: 1.5;
    font-weight: lighter;
}

.chordBoxE{
    font-family: 'Georgia', serif;
    font-size: 23px;
    line-height: 1.5;
    font-weight: lighter;
}

@media only screen and (max-width: 992px) {
    .welcomeDiv{
        padding-left: 15px;
        padding-right: 15px
    }
    
    .welcomeDiv h1{
        font-size: 25px;
    }
    
    .fhclive{
        font-size: 50px;
    }

    .Ctitles{
        width:90%
    }
    .Ctitle{
        font-size: 20px;
    }
    
    .chordDiv{
        margin: auto;
        margin-top: 30px;
        width:95%;
        padding:23px;
        
        font-size: 18px;
        
    }

    .tools{
        height: auto;
        padding:25px;
        background-color: #fff;
        position: unset;
        width: 90%;
        margin:auto;
        margin-top: 60px;
        box-shadow: 1px 1px 13px 1px #808080;
        text-align: center;
    }

    .langButton{
        background-color: #fff;
        border: 0px;
        width: 265px;
        margin-left:25px;
        margin-top: 30px;
        margin-bottom: 7px;
        box-shadow: 1px 1px 5px 1px #000;
        border-radius: 7px;
    }

}
@-webkit-keyframes example {
    0%   {background-image:  linear-gradient(90deg,#41295a,#2F0743);}
    5%  {background-image:  linear-gradient(110deg,#41295a,#2F0743);}
    10%  {background-image: linear-gradient(130deg,#41295a,#2F0743);}
    15%  {background-image: linear-gradient(150deg,#41295a,#2F0743);}
    20%  {background-image: linear-gradient(170deg,#41295a,#2F0743);}
    25%  {background-image: linear-gradient(190deg,#41295a,#2F0743);}
    30%  {background-image: linear-gradient(210deg,#41295a,#2F0743);}
    35%  {background-image: linear-gradient(230deg,#41295a,#2F0743);}
    40%  {background-image: linear-gradient(250deg,#41295a,#2F0743);}
    45%  {background-image: linear-gradient(270deg,#41295a,#2F0743);}
    50%  {background-image: linear-gradient(290deg,#41295a,#2F0743);}
    55%  {background-image: linear-gradient(310deg,#41295a,#2F0743);}
    60%  {background-image: linear-gradient(330deg,#41295a,#2F0743);}
    65%  {background-image: linear-gradient(350deg,#41295a,#2F0743);}
    70% {background-image:  linear-gradient(370deg,#41295a,#2F0743);}
    75%  {background-image: linear-gradient(390deg,#41295a,#2F0743);}
    80% {background-image:  linear-gradient(410deg,#41295a,#2F0743);}
    85%  {background-image: linear-gradient(420deg,#41295a,#2F0743);}
    90% {background-image:  linear-gradient(430deg,#41295a,#2F0743);}
    95%  {background-image: linear-gradient(440deg,#41295a,#2F0743);}
    100%  {background-image:linear-gradient(450deg,#41295a,#2F0743);}
    
}

@keyframes example {
    0%   {background-image:  linear-gradient(90deg,#41295a,#2F0743);}
    5%  {background-image:  linear-gradient(110deg,#41295a,#2F0743);}
    10%  {background-image: linear-gradient(130deg,#41295a,#2F0743);}
    15%  {background-image: linear-gradient(150deg,#41295a,#2F0743);}
    20%  {background-image: linear-gradient(170deg,#41295a,#2F0743);}
    25%  {background-image: linear-gradient(190deg,#41295a,#2F0743);}
    30%  {background-image: linear-gradient(210deg,#41295a,#2F0743);}
    35%  {background-image: linear-gradient(230deg,#41295a,#2F0743);}
    40%  {background-image: linear-gradient(250deg,#41295a,#2F0743);}
    45%  {background-image: linear-gradient(270deg,#41295a,#2F0743);}
    50%  {background-image: linear-gradient(290deg,#41295a,#2F0743);}
    55%  {background-image: linear-gradient(310deg,#41295a,#2F0743);}
    60%  {background-image: linear-gradient(330deg,#41295a,#2F0743);}
    65%  {background-image: linear-gradient(350deg,#41295a,#2F0743);}
    70% {background-image:  linear-gradient(370deg,#41295a,#2F0743);}
    75%  {background-image: linear-gradient(390deg,#41295a,#2F0743);}
    80% {background-image:  linear-gradient(410deg,#41295a,#2F0743);}
    85%  {background-image: linear-gradient(420deg,#41295a,#2F0743);}
    90% {background-image:  linear-gradient(430deg,#41295a,#2F0743);}
    95%  {background-image: linear-gradient(440deg,#41295a,#2F0743);}
    100%  {background-image:linear-gradient(450deg,#41295a,#2F0743);}
    
}

.lyricbody{
    background-image: linear-gradient(90deg,#00c6ff,#0072ff);
    height: auto;
    width:100%;
    padding-top: 70px;
    padding-bottom: 70px;
}

.welcomeDiv{
    margin-top:150px;
    text-align: center;
    margin-bottom: 150px;
    color:#fff;
    font-family: Georgia, serif
}

.welcomeDiv h1{
    font-size: 50px;
}

.fhclive{
    font-size: 75px;
}



.titles{
    width: 60%;
    margin: auto;
    height:auto;
    padding-top: 50px;
    box-shadow: 1px 1px 30px 1px #727171;
    border-radius: 1px;
    /*background-image: url("./images/lyricBag.png");
    background-repeat: repeat-y;
    background-position: right;
    background-clip: border-box;
    */
    background-color: white;
    border-radius: 13px;
    padding-bottom: 50px;
}

.title{
    width:90%;
    margin:auto;
    background-image: linear-gradient(45deg,#00c6ff,#0072ff);
    margin-bottom: 10px;
    text-align: center;
    padding:7px;
    border-radius: 7px;
    font-size: 25px;
    font-family: 'Girassol', cursive;
    -webkit-animation-name: exampl;
            animation-name: exampl;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -moz-animation-timing-function: initial;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
   /* box-shadow: 1px 1px 10px 1px rgb(57, 78, 117);*/
}

.titleWrap{
    padding: 0px 0px;
    padding-top: 100px;
}

.teluguTitle{
    font-family: "Dhurjati"; 
    font-size: "40px" 
}
.titleLink{
    color: #fff;
    font-size: 25px;
}

.titleAlpha{
    width:90%;
    margin:auto;
    margin-bottom: 15px;
    font-size: 30px;
    font-family: 'Girassol', cursive;
}

.lyricNav{
    width: 80%;
    margin: auto;
}

.titleAlphaM{
    position: relative;
    float:left;
    margin:5px;
    font-size: 25px;
    font-family: 'Girassol', cursive;
}


.lyricpage{
    height: auto;
    width:100%;
    padding-top: 70px;
    background-image: linear-gradient(45deg,#780206,#061161);
    padding-bottom: 50px;

}
.lyricTitle{
    font-family: "Dhurjati";
    font-size: 55px; 
    margin-bottom:30px;
}
.lyricDiv{
    background-color: white;
    width:60%;
    margin-left:25px;
    padding:30px;
    margin-top: 0px;

    box-shadow: 0px 0px 13px 1px #FFF;
    font-size: 20px;
    border-radius: 2px;
}

.lyricData{
    margin-bottom: 50px;
}

.tools{
    height: auto;
    padding:25px;
    background-color: #fff;
    width: 25%;
    margin:auto;
    margin-top: 173px;
    margin-right: 20px;
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    box-shadow: 1px 1px 13px 1px #808080;
    text-align: center;
}

.back{
    font-family: 'Patua One', cursive;
    margin-left:25px;
    font-size: 25;
    color: #fff;
    width: 65px;
    padding:2px;
    padding-left: 0px;
    border-radius: 5px;
    background-color: rgb(26, 1, 1);
    border: none;
    box-shadow: 1px 1px 5px 1px #000;
    
}

.lyriclangButton{
    background-color: #fff;
    border: 2px solid #000;
    border-bottom: 0px;
    width: 275px;
    margin-left:30%;
    margin-top: 50px;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    border-radius: 7px;
}

.lyricBox{
    font-family: 'Sree Krushnadevaraya', serif;
    font-size: 25px;
    line-height: 1.5;
    font-weight: lighter;
}

.lyricBoxE{
    font-family: 'Georgia', serif;
    font-size: 23px;
    line-height: 1.5;
    font-weight: lighter;
}

@media only screen and (max-width: 992px) {
    .welcomeDiv{
        padding-left: 15px;
        padding-right: 15px
    }
    
    .welcomeDiv h1{
        font-size: 25px;
    }
    
    .fhclive{
        font-size: 50px;
    }

    .titles{
        width:90%
    }
    .title{
        font-size: 20px;
    }
    
    .lyricDiv{
        margin: auto;
        margin-top: 30px;
        width:95%;
        padding:23px;
        
    }

    .lyricDiv h1{
        font-size: 40px;
    }

    .tools{
        height: auto;
        padding:25px;
        background-color: #fff;
        position: unset;
        width: 90%;
        margin:auto;
        margin-top: 60px;
        box-shadow: 1px 1px 13px 1px #808080;
        text-align: center;
    }

    .lyriclangButton{
        background-color: #fff;
        border: 0px;
        width: 265px;
        margin-left:25px;
        margin-top: 50px;
        margin-bottom: 7px;
        box-shadow: 1px 1px 5px 1px #000;
        border-radius: 7px;
    }

    .lyricBox{
        font-size: 23px;
    }
    
    .lyricBoxE{
        font-size: 20px;
    }

}
.contactPage{
    height: 85vh;
    color:#fff;
    background-color: #000;
    padding-top: 20px;
    text-align: center;
    font-family: 'Girassol';
    box-shadow: 0px 0px 25px 1px #000;
}

.contactPage h1{
    font-size: 50px;
    /*text-shadow: 0px 0px 0px #fff;
*/
}

.address{
    margin-top: 30px;
    margin-left: 30px;
    padding-left: 30px;
    text-align: left;
    position: relative;
    float: left;
    width: 30%;
    
}

.address h1{
    color: #fff;
    /*background: -webkit-linear-gradient(135deg,#9733EE,#9733EE);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    */
    font-size: 30px;
    text-shadow: 0px 0px 0px #fff;
}

.address p{
    font-size: 20px;
    font-weight: lighter;
}

.address a{
    color: #fff;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
}


@media (max-width: 991.98px) {

.contactPage{
    height: 200vh;
    background-color: #000;
    padding-top: 100px;
    text-align: center;
}
.address{
    margin-left: 5px;
    padding-left: 30px;
    text-align: left;
    position: relative;
    float: left;
    width: 90%
}


}
.qnavPage{
    padding-top: 70px;
    /*background-image: linear-gradient(180deg,#DA22FF, #f83600); 
    */
    background-color: #000;;
    height: 100vh;
    /* Black background with opacity */
    box-shadow: 0px 0px 0px 0px #000;
}

.qnavPage h1{
    text-align: center;
    font-family: "Girassol";
    color:#fff;
    
}

.buttons{
    width:50%;
    margin:auto
}

.button{
    text-decoration: none;
    background-color: #fff;
    width: auto;
    padding:1px 20px;
    font-size: 30px;
    text-align: center;
    margin-left: 55px;
    margin-top: 35px;
    border-radius: 10px;
    position: relative;
    float: left;
    box-shadow: 0px 0px 5px 1px #fff;
}

.button a{
    font-family: "Girassol";
    color:#000;
}

@media only screen and (max-width: 992px) {
.buttons{
    width:95%;
    margin:auto
}

.button{
    text-decoration: none;
    background-color: #fff;
    width: auto;
    padding:1px 20px;
    font-size: 25px;
    text-align: center;
    margin-left: 50px;
    border-radius: 10px;

}

}

.scrollTop{
    background-color: transparent;
    color: #fff;
    font-size: 40px;
    display: hidden;
    text-align: center;
    border-radius: 5px;
    position: fixed;
    bottom: 0;
    right:0;
    margin: 50px;
    z-index: 10;
}

.scrollTop button{
    border-radius: 50%;
    width: 55px;
    height: 55px;
    border: 0px;

    background-color: #fff;
}


.scrollTop img{
margin-bottom: 20px;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 1px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000; 
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(0deg,#833ab4,#fd1d1d,#fcb045);
  border-radius: 5px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  scroll-behavior: smooth;
}
/*}*/
.underConstruction{
  position:fixed;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index:10;
  top:0;
  bottom:0;
  left: 0;
  right:0;
  overflow-y: auto;
}

.underConstruction img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 150px;
}

@media only screen and (max-width: 992px) {
  .underConstruction img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 150px;
  }
  

}
.navuni{
  position: fixed;
  z-index: 10;
}
.nav-item{
  color: #fff;
  font-size: 17px;
  margin: 3px;
  font-family: '"Trebuchet MS", Helvetica, sans-serif';
}

.navbar{
  transition: all 0.4s;
  z-index: 10;
}


@media (max-width: 991.98px) {


}

.home{
  padding-top: 60px;
}

.homebg {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index:-9;
  background-image: url(/static/media/bg_1.8baa35fa.jpg)
}

.bgoverlay{
  position: fixed; /* Sit on top of the page content */
   /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg,#08c299, #090089);/*#000, #000); /* Black background with opacity */
  z-index: -9; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  opacity: 0.6;
}
.wel{
  color: #fff;
  width: 50vw;
  margin: 0 auto;
  margin-top: 25vh;
  text-align:center;
  font-size: 30px;
}

.scroll{
  font-size: 15px;
  margin:0 auto;
  margin-top: 10px;
  color:#fff;
  background-color:transparent;
  text-align: center;
  width:100px;
  height:40px;
  padding:6.5px;
  border: 2px solid #fff;
  border-radius: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  
}

.scroll:hover{
  font-size: 15px;
  margin:0 auto;
  margin-top: 10px;
  background-color: #fff;
  color:#000;
  text-align: center;
  width:100px;
  height:40px;
  padding:6.5px;
  border: 2px solid #fff;
  border-radius: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bolder;
  box-shadow: 0px 0px 10px 5px #fff;
}



.fhc{
  font-size: 50px;
}
.blogback{
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index:-10
}

.searchBar{
  height: 50px;
}

.bloglist{
  width:60%;
  margin:auto;
  
}

.bloglist-item{
  width:80%;
  margin: auto;
  background: -webkit-linear-gradient(90deg,#08c299, #090089);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bloglist-content{
  max-height:90px;
  overflow: hidden;
  text-align: center;
  font-family: 'Chathura', sans-serif;
  font-weight: 900;
  font-size: 20px;
}
.blogl-container{
  background-color: white;

  height:1000vh
}


.blog-container h1{
  font-size: 50px;
  font-weight: bold;
}

.blog-list-uni{
padding: 7px 20px;
padding-bottom: 20px;
border-radius: 10px;
margin: 30px 0px;
box-shadow: 1px 5px 10px 1px #808080;
}
.bloglist-h1{
  font-size: 60px;
  text-align: center;
  /*font-family: 'Dhurjati', sans-serif;*/
  font-weight: bold;
  font-family: 'Chathura', sans-serif;
  line-height: 0.7;
  
}
.englishtitle{
font-size: 25px;
font-weight: bolder;
}
.blog-universal{
margin-top:70px;
}

.blog-h1{
  height:50vh;
  padding:10% 1%;
}
.blog-h1 h1{
  font-size:50px;
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color:#fff;
}


.blog{
  background: #fff; 
  width:      100%;
  height:     100vh; 
  margin-top: 60px;
  scroll-behavior: unset;
}

.blogcon{
  width: 60%;
  margin: auto;
  padding: 30px;
  background-color: #fff;
  /*font-family: 'Chathura', sans-serif;
  */
  font-family: 'Mandali', sans-serif;
  font-size: 23px;
  font-weight: 00;
  color:#000;
}

.blogcon h1{
  text-align: center;
  font-size: 60px;
  font-family: 'Dhurjati', sans-serif;
  color:rgb(88, 81, 81);
}

.second{
  height: auto;
  /*background-color: #fff;
  */
  background-image: linear-gradient(215deg,#000, #000); /*#fff, #fff,#fff, #fff,#fff,#fff, rgb(168, 167, 167)); *//* Black background with opacity */
  text-align: center;
  padding-top: 125px;
  font-family: 'Old Standard TT', serif;
  padding-bottom: 100px;
  box-shadow: 0px 0px 25px 1px #000;
  display: none;
}

.secondAbout{
  width: 50%;
  margin:auto;
  
}

.second h1{
  font-size: 50px;
 /* background: -webkit-linear-gradient(0deg,#833ab4, #fd1d1d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;*/
  color:#fff;
}

.secondPara{
  text-align: center;
  font-size:30px;
  font-family: 'Times New Roman', sans-serif;
  color: #fff
}

.scrollB{
  font-size: 15px;
  margin:0 auto;
  margin-top: 25px;
  color:#000;
  background-color:transparent;
  text-align: center;
  width:100px;
  height:40px;
  padding:6.5px;
  border: 2px solid #000;
  border-radius: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  
}

.scrollB:hover{
  font-size: 18px;
  margin:0 auto;
  margin-top: 25px;
  background-color: rgb(255, 0, 119);
  color:#fff;
  text-align: center;
  width:100px;
  height:40px;
  padding:4.5px;
  border: 2px solid rgb(255, 0, 119);
  border-radius: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bolder;
  box-shadow: 0px 0px 10px 5px rgb(255, 0, 119);
  text-decoration: none;
}

#fourth{
  height: 100%;
}


@media only screen and (max-width: 992px) {
  .ltext{
    font-size: 20px;
  }
  .home{
    height: auto;
  }
  .wel{
    color: #fff;
    font-size: 4vw;
    width: 100%;
    height:100%;
    margin: auto;
    margin-top: 25vh;
    text-align: center
  } 

  .fhc{
    font-size: 6vw;
  }
  .bloglist{
    width:95%;
    margin:auto;
  }

  .bloglist-h1{
    font-size: 45px;
    
  }
  .blog-h1{
    padding-top: 20vh;
  }
  .blog-h1 h1{
  
    font-size:30px;
  }
  .blogcon{
    width: 100%;
    margin:auto;
    margin-top: 20vh;
    padding-left: 13px;
    padding-right: 7px;
    
  }
  .blogcon h1{
    font-size: 50px;
  }
  
  .secondAbout{
    width: 95%;
    margin:auto;
  }

  .secondPara{
    margin-top: 20px;
    text-align: center;
    font-size:25px;
    text-align: left;
    padding:5px
  }

}
/* Extra small devices (phones, 600px and down) 
@media only screen and (max-width: 600px) {...}*/

/* Small devices (portrait tablets and large phones, 600px and up) 
@media only screen and (min-width: 600px) {...}*/

/* Medium devices (landscape tablets, 768px and up) 
@media only screen and (min-width: 768px) {...}*/

/* Large devices (laptops/desktops, 992px and up) 
@media only screen and (min-width: 992px) {...}*/

/* Extra large devices (large laptops and desktops, 1200px and up) 
@media only screen and (min-width: 1200px) {...}  */ 
