.qnavPage{
    padding-top: 70px;
    /*background-image: linear-gradient(180deg,#DA22FF, #f83600); 
    */
    background-color: #000;;
    height: 100vh;
    /* Black background with opacity */
    box-shadow: 0px 0px 0px 0px #000;
}

.qnavPage h1{
    text-align: center;
    font-family: "Girassol";
    color:#fff;
    
}

.buttons{
    width:50%;
    margin:auto
}

.button{
    text-decoration: none;
    background-color: #fff;
    width: auto;
    padding:1px 20px;
    font-size: 30px;
    text-align: center;
    margin-left: 55px;
    margin-top: 35px;
    border-radius: 10px;
    position: relative;
    float: left;
    box-shadow: 0px 0px 5px 1px #fff;
}

.button a{
    font-family: "Girassol";
    color:#000;
}

@media only screen and (max-width: 992px) {
.buttons{
    width:95%;
    margin:auto
}

.button{
    text-decoration: none;
    background-color: #fff;
    width: auto;
    padding:1px 20px;
    font-size: 25px;
    text-align: center;
    margin-left: 50px;
    border-radius: 10px;

}

}