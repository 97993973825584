@import url('https://fonts.googleapis.com/css2?family=Chathura:wght@100&family=Dhurjati&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Girassol&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mandali&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&family=Old+Standard+TT:wght@700&display=swap');/*}*/
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
.underConstruction{
  position:fixed;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index:10;
  top:0;
  bottom:0;
  left: 0;
  right:0;
  overflow-y: auto;
}

.underConstruction img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  margin-top: 150px;
}

@media only screen and (max-width: 992px) {
  .underConstruction img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 150px;
  }
  

}
.navuni{
  position: fixed;
  z-index: 10;
}
.nav-item{
  color: #fff;
  font-size: 17px;
  margin: 3px;
  font-family: '"Trebuchet MS", Helvetica, sans-serif';
}

.navbar{
  transition: all 0.4s;
  z-index: 10;
}


@media (max-width: 991.98px) {


}

.home{
  padding-top: 60px;
}

.homebg {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index:-9;
  background-image: url("./images/bg_1.jpg")
}

.bgoverlay{
  position: fixed; /* Sit on top of the page content */
   /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg,#08c299, #090089);/*#000, #000); /* Black background with opacity */
  z-index: -9; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  opacity: 0.6;
}
.wel{
  color: #fff;
  width: 50vw;
  margin: 0 auto;
  margin-top: 25vh;
  text-align:center;
  font-size: 30px;
}

.scroll{
  font-size: 15px;
  margin:0 auto;
  margin-top: 10px;
  color:#fff;
  background-color:transparent;
  text-align: center;
  width:100px;
  height:40px;
  padding:6.5px;
  border: 2px solid #fff;
  border-radius: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  
}

.scroll:hover{
  font-size: 15px;
  margin:0 auto;
  margin-top: 10px;
  background-color: #fff;
  color:#000;
  text-align: center;
  width:100px;
  height:40px;
  padding:6.5px;
  border: 2px solid #fff;
  border-radius: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bolder;
  box-shadow: 0px 0px 10px 5px #fff;
}



.fhc{
  font-size: 50px;
}
.blogback{
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index:-10
}

.searchBar{
  height: 50px;
}

.bloglist{
  width:60%;
  margin:auto;
  
}

.bloglist-item{
  width:80%;
  margin: auto;
  background: -webkit-linear-gradient(90deg,#08c299, #090089);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bloglist-content{
  max-height:90px;
  overflow: hidden;
  text-align: center;
  font-family: 'Chathura', sans-serif;
  font-weight: 900;
  font-size: 20px;
}
.blogl-container{
  background-color: white;

  height:1000vh
}


.blog-container h1{
  font-size: 50px;
  font-weight: bold;
}

.blog-list-uni{
padding: 7px 20px;
padding-bottom: 20px;
border-radius: 10px;
margin: 30px 0px;
box-shadow: 1px 5px 10px 1px #808080;
}
.bloglist-h1{
  font-size: 60px;
  text-align: center;
  /*font-family: 'Dhurjati', sans-serif;*/
  font-weight: bold;
  font-family: 'Chathura', sans-serif;
  line-height: 0.7;
  
}
.englishtitle{
font-size: 25px;
font-weight: bolder;
}
.blog-universal{
margin-top:70px;
}

.blog-h1{
  height:50vh;
  padding:10% 1%;
}
.blog-h1 h1{
  font-size:50px;
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color:#fff;
}


.blog{
  background: #fff; 
  width:      100%;
  height:     100vh; 
  margin-top: 60px;
  scroll-behavior: unset;
}

.blogcon{
  width: 60%;
  margin: auto;
  padding: 30px;
  background-color: #fff;
  /*font-family: 'Chathura', sans-serif;
  */
  font-family: 'Mandali', sans-serif;
  font-size: 23px;
  font-weight: 00;
  color:#000;
}

.blogcon h1{
  text-align: center;
  font-size: 60px;
  font-family: 'Dhurjati', sans-serif;
  color:rgb(88, 81, 81);
}

.second{
  height: auto;
  /*background-color: #fff;
  */
  background-image: linear-gradient(215deg,#000, #000); /*#fff, #fff,#fff, #fff,#fff,#fff, rgb(168, 167, 167)); *//* Black background with opacity */
  text-align: center;
  padding-top: 125px;
  font-family: 'Old Standard TT', serif;
  padding-bottom: 100px;
  box-shadow: 0px 0px 25px 1px #000;
  display: none;
}

.secondAbout{
  width: 50%;
  margin:auto;
  
}

.second h1{
  font-size: 50px;
 /* background: -webkit-linear-gradient(0deg,#833ab4, #fd1d1d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;*/
  color:#fff;
}

.secondPara{
  text-align: center;
  font-size:30px;
  font-family: 'Times New Roman', sans-serif;
  color: #fff
}

.scrollB{
  font-size: 15px;
  margin:0 auto;
  margin-top: 25px;
  color:#000;
  background-color:transparent;
  text-align: center;
  width:100px;
  height:40px;
  padding:6.5px;
  border: 2px solid #000;
  border-radius: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  
}

.scrollB:hover{
  font-size: 18px;
  margin:0 auto;
  margin-top: 25px;
  background-color: rgb(255, 0, 119);
  color:#fff;
  text-align: center;
  width:100px;
  height:40px;
  padding:4.5px;
  border: 2px solid rgb(255, 0, 119);
  border-radius: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bolder;
  box-shadow: 0px 0px 10px 5px rgb(255, 0, 119);
  text-decoration: none;
}

#fourth{
  height: 100%;
}


@media only screen and (max-width: 992px) {
  .ltext{
    font-size: 20px;
  }
  .home{
    height: auto;
  }
  .wel{
    color: #fff;
    font-size: 4vw;
    width: 100%;
    height:100%;
    margin: auto;
    margin-top: 25vh;
    text-align: center
  } 

  .fhc{
    font-size: 6vw;
  }
  .bloglist{
    width:95%;
    margin:auto;
  }

  .bloglist-h1{
    font-size: 45px;
    
  }
  .blog-h1{
    padding-top: 20vh;
  }
  .blog-h1 h1{
  
    font-size:30px;
  }
  .blogcon{
    width: 100%;
    margin:auto;
    margin-top: 20vh;
    padding-left: 13px;
    padding-right: 7px;
    
  }
  .blogcon h1{
    font-size: 50px;
  }
  
  .secondAbout{
    width: 95%;
    margin:auto;
  }

  .secondPara{
    margin-top: 20px;
    text-align: center;
    font-size:25px;
    text-align: left;
    padding:5px
  }

}
/* Extra small devices (phones, 600px and down) 
@media only screen and (max-width: 600px) {...}*/

/* Small devices (portrait tablets and large phones, 600px and up) 
@media only screen and (min-width: 600px) {...}*/

/* Medium devices (landscape tablets, 768px and up) 
@media only screen and (min-width: 768px) {...}*/

/* Large devices (laptops/desktops, 992px and up) 
@media only screen and (min-width: 992px) {...}*/

/* Extra large devices (large laptops and desktops, 1200px and up) 
@media only screen and (min-width: 1200px) {...}  */ 